import React, { useEffect, useState } from "react";
import PageHeader from "../../components/pageHeaders";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  notification,
  Select,
} from "antd";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading";
import Taskdatatable from "./datatable";
import { API } from "../../config/apis";
import { DELETE, POST } from "../../utils/apiRequest";
import StatusFilter from "./components/statusFilter.json";
import Viewscreen from "../viewModal/view";

function Taskscreen() {
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [activities, setActivities] = useState<any>([]);
  const [users, setUsers] = useState([]);
  const [task, setTasks] = useState([]);
  const [querry, setQuerry] = useState("");
  const [open, setOpen] = useState(false);
  const [Notifications, contextHolder] = notification.useNotification();
  const [statusFilter, setStatusFilter] = useState("");

  useEffect(() => {
    getTasks(querry, statusFilter, page, take);
  }, []);

  const getTasks = async (query: any, status: any, page: any, take: any) => {
    try {
      let api = "task/list";
      const obj = { query, page, take, status };
      console.log("paylaod ----------->", obj);
      const response: any = await POST(api, obj);
      if (response?.status) {
        setTasks(response?.data);
        setMeta(response?.meta);
        setIsLoading(false);
        // setMeta(response?.meta);
      } else {
        setTasks([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteTasks = async (id: any) => {
    try {
      setIsLoading2(true);
      let api = API.TASK_DELETE + id;
      let Delete: any = await DELETE(api);
      if (Delete?.status) {
        setIsLoading2(false);
        Notifications["success"]({
          message: "task Deleted",
        });
        getTasks(querry, statusFilter, page, take);
      } else {
        setIsLoading2(false);
        Notifications["error"]({
          message: "task not deleted",
        });
      }
    } catch (err) {
      setIsLoading2(false);
      Notifications["error"]({
        message: "Network error. task not deleted",
      });
    }
  };

  return (
    <div>
      {contextHolder}
      <PageHeader loading={isLoading2} backButton={true} title={"Task"}>
        <Form
        //  form={form} onValuesChange={getBooking}
        >
          <div className="bookingScreen-PageHeaderBox">
            <Form.Item name={"query"} noStyle>
              <Input
                style={{ width: 200 }}
                placeholder="Search"
                allowClear
                onChange={(value: any) => {
                  getTasks(value?.target?.value, statusFilter, page, take);
                  setQuerry(value?.target?.value);
                }}
                suffix={<CiSearch size={16} color="#000" />}
              />
            </Form.Item>
            &nbsp; &nbsp;
            <Select
              style={{ width: 200 }}
              placeholder="Status"
              allowClear
              onChange={(val) => {
                setStatusFilter(val);
                getTasks(querry, val, page, take);
              }}
            
              filterOption={false}

            >
              {StatusFilter.map((item: any) => (
                <Select.Option key={item.id} value={item.value}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
            {/* <Form.Item name={"UserId"} noStyle>
              <Input placeholder="User id" style={{ width: 200 }} allowClear />
            </Form.Item> */}
            &nbsp; &nbsp;
            <Form.Item noStyle>
              <Button
                onClick={() => navigate("/Auth/taskScreen/Taskcreate")}
                type="primary"
              >
                Create +
              </Button>
            </Form.Item>
          </div>
        </Form>
      </PageHeader>
      <Card>
        {isLoading ? (
          <Loading />
        ) : (
          <Taskdatatable
            data={task}
            //   onEdit={(item: any) => createOrEditBooking(item)}
            //   onView={(item: any) => onView(item)}
            delete={(id: any) => deleteTasks(id)}
            meta={meta}
            onPageChange={(page: number, take: number) => {
              setIsLoading2(true);
              setPage(page);
              setTake(take);
              getTasks(querry, statusFilter, page, take);
            }}
            setOpen={setOpen}
            open={open}
          />
        )}
        <Viewscreen />
      </Card>
    </div>
  );
}

export default Taskscreen;
