import { Pagination, Popconfirm, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit, CiRead } from "react-icons/ci";
import { FaTrash } from "react-icons/fa6";
import { IoEyeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

function UserDataTable(props: any) {
  const navigate = useNavigate();
  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      render: (id: any, __: any, rowIndex: number) => {
        return <div className="table-Txt">{rowIndex + 1}</div>;
      },
    },
    {
      title: "Employee ID",
      dataIndex: "employeeId",
      key: "employeeId",
      render: (record: any, row: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "name",
      render: (record: any, row: any) => {
        return (
          <div className="table-Txt">
            {row.firstName} {row.lastName}
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    // {
    //   title: "Last Name",
    //   dataIndex: "firstName",
    //   key: "firstName",
    //   render: (record: any) => {
    //     return <div className="table-Txt">{record}</div>;
    //   },
    // },
    {
      title: "Office Email",
      dataIndex: "officeEmail",
      key: "officeEmail",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },

    {
      title: "Action",
      with: 10,
      render: (item: any) => (
        <div
          className="table-item"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <IoEyeOutline
              onClick={() => {
                props?.view(true,item?.id);
              }}
              size={20}
              style={{ color: "blue", cursor: "pointer" }}
            />
            {/* </Tooltip> */}
          </div>
          <div>
            <Tooltip title="Edit" mouseEnterDelay={0.5} color="#8f8e8d">
              <CiEdit
                size={20}
                onClick={() =>
                  navigate("/Auth/userScreen/userCreate", {
                    state: { id: item?.id },
                  })
                }
                // onClick={() => console.log("item -->", item)}
                style={{ cursor: "pointer", color: "orange" }}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Delete" mouseEnterDelay={0.5} color="#8f8e8d">
              <Popconfirm
                title="Delete the User"
                description="Are you sure you want to Users"
                onConfirm={() => props?.delete(item?.id)}
                okText="Yes"
              >
                <AiOutlineDelete
                  size={20}
                  style={{ color: "red", cursor: "pointer" }}
                />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
      />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total) => `Total ${props?.meta?.total_count} Task`}
          onChange={(page, take) => props.onPageChange(page, take)}
          pageSizeOptions={[10, 20, 50, 100]}
          defaultPageSize={10}
        />
      </div>
    </>
  );
}

export default UserDataTable;
