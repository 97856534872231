import { Button, Card, DatePicker, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/pageHeaders";
import { API } from "../../config/apis";
import { GET, POST } from "../../utils/apiRequest";
import DataTable from "./dataTable";

function DailytaskScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [date, setDate] = useState<any>([]);
  const [search, setSearch] = useState<any>();
  const { RangePicker } = DatePicker;
  const [page, setPage] = useState(1);

  const [take, setTake] = useState(10);
  const [createdBy, setCreatedBy] = useState<number>();

  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      let api = API.GET_USER + `?page=1&take=1000`;
      const response: any = await GET(api, null);
      console.log("response===========><><><", response);

      if (response?.status) {
        setUsers(response?.data);
        setIsLoading(false);
      } else {
        setUsers([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getDailytask(page, take, createdBy);
  }, [date, search]);
  const getDailytask = async (page: any, take: any, by: any) => {
    try {
      let api = API.DAILY_TASK_LIST;

      const response: any = await POST(api, {
        createdBy: by,
        page,
        take,
        fromDate: date?.[0],
        toDate: date?.[1],
        taskName: search,
      });
      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
      } else {
        setData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div>
        <PageHeader backButton={true} title={"Task"}>
          <Form>
            <div className="bookingScreen-PageHeaderBox">
              {/* <Form.Item name={"query"} noStyle>
                <Input
                  style={{ width: 200 }}
                  placeholder="Search"
                  allowClear
                  onChange={handleSearchChange}
                  suffix={<CiSearch size={16} color="#000" />}
                />
              </Form.Item> */}
              <Select
                style={{ width: 200 }}
                placeholder="Select User"
                allowClear
                onChange={(val) => {
                  console.log(val, "val=======================<><><><><><><");
                  setCreatedBy(Number(val));
                  getDailytask(page, take, Number(val));
                }}
                loading={isLoading}
                filterOption={false}
              >
                {users.map((user: any) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.firstName + " " + user.lastName}
                  </Select.Option>
                ))}
              </Select>
              &nbsp; &nbsp;
              <Form.Item name={"date"} noStyle>
                <RangePicker
                  format="YYYY-MM-DD"
                  onChange={(value, dateString) => {
                    setDate(dateString);
                    console.log("Selected Time: ", value);
                    console.log("Formatted Selected Time: ", dateString);
                  }}
                />
              </Form.Item>
              &nbsp; &nbsp;
              <Form.Item noStyle>
                <Button
                  onClick={() =>
                    navigate("/Auth/DailytaskScreen/Dailytaskcreate")
                  }
                  type="primary"
                >
                  Create +
                </Button>
              </Form.Item>
            </div>
          </Form>
        </PageHeader>
        <Card>
          {/* {isLoading ? (
          <Loading />
        ) : (
         
        )} */}
          {/* <Viewscreen /> */}
        </Card>
        <DataTable data={data} />
      </div>
    </>
  );
}

export default DailytaskScreen;
