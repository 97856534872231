import { Button, Form, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { GET, POST } from "../../utils/apiRequest";
import { API } from "../../config/apis";
import { useForm } from "antd/es/form/Form";
import "./styles.scss";
import PageHeader from "../../components/pageHeaders";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function UserCreate() {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [isLoading2, setIsLoading2] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};

  const onFinish = async (val: any) => {
    const payload = {
      project: val.project,
      phone: val.phone,
      email: val.email,
      id: Number(id),
      firstName: val.firstName,
      lastName: val.lastName,
      officeEmail: val.officeEmail,
      password: val.password,
    };
    let url = id ? API.USER_EDIT : API.USER_CREATE;
    const data: any = await POST(url, payload);
    if (data?.status) {
      navigate(-1);
      notification.success({
        message: `Success`,
        description: `User Successfully ${id ? "Updated" : "Created"}`,
      });
      setIsLoading(false);
    } else {
      notification.error({
        message: "Faild",
        description: data.message,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) loadUserDetails();
  }, []);

  const loadUserDetails = async () => {
    try {
      setIsLoading2(true);
      let api = API.GET_USER_ID + id;
      const response: any = await GET(api, null);
      console.log("response=============", response);
      if (response?.status) {
        const data = response?.data;
        setUserDetails(response?.data);
        form.setFieldsValue({
          employeeId: data?.employeeId,
          firstName: data?.firstName,
          lastName: data?.lastName,
          phone: data?.phone,
          email: data?.email,
          officeEmail: data?.officeEmail,
        });
      }
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  return (
    // <Container>
    <>
      <PageHeader loading={isLoading2} backButton={true} title={"Create"}>
        {" "}
      </PageHeader>
      <br />

      <div className="create-Box1" style={{ padding: "50px" }}>
        <Form
          form={form}
          layout="vertical"
          name="userCreationForm"
          onFinish={onFinish}
        >
          <Row>
            <Col md={6}>
              <Form.Item
                label="Phone"
                name="phone"
                
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  {
                    pattern: /^\d{10}$/,
                    message: "Please enter a valid 10-digit phone number!",
                  },
                ]}
              >
                <Input
                  className="user-inputs"
                  size="large"
                  type="number"
                  placeholder="Enter phone number"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  {
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                ]}
              >
                <Input
                  className="user-inputs"
                  size="large"
                  placeholder="Enter email"
                />
              </Form.Item>
            </Col>

            <Col md={6}>
              <Form.Item
                label="firstName"
                name="firstName"
                rules={[
                  { required: true, message: "Please input the firstName!" },
                ]}
              >
                <Input
                  className="user-inputs"
                  size="large"
                  placeholder="Enter your firstName"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="lastName"
                name="lastName"
                rules={[
                  { required: true, message: "Please input the lastName!" },
                ]}
              >
                <Input
                  className="user-inputs"
                  size="large"
                  placeholder="Enter lastName"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="officeEmail"
                name="officeEmail"
                rules={[
                  { required: true, message: "Please input the officeEmail!" },
                ]}
              >
                <Input
                  className="user-inputs"
                  size="large"
                  placeholder="Enter officeEmail"
                  type="email"
                />
              </Form.Item>
            </Col>
            {id ? null : (
              <>
                <Col md={6}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      { required: true, message: "Please enter password!" },
                    ]}
                  >
                    <Input.Password
                      className="user-input"
                      size="large"
                      placeholder="Enter password"
                      iconRender={(visible) =>
                        visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    label="Confirm Password"
                    name="confirm_password"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Passwords do not match!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      className="user-inputs"
                      size="large"
                      placeholder="Confirm password"
                      iconRender={(visible) =>
                        visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
                      }
                    />
                  </Form.Item>
                </Col>
              </>
            )}

            <Row>
              <Col md={6} />

              <Col md={3}>
                <Button size="large" danger block onClick={() => navigate(-1)}>
                  Close
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default UserCreate;
