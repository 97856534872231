export const API = {
      // BASE_URL: "http://localhost:8026/",
        BASE_URL: "https://server.bairuhatech.com/task-manager-server/",

      LOGIN: "user/login",
      USER_CREATE: "user/create",
      GET_USER: "user/picker",
      USER_LIST: 'user/list',
      TASK_CREATE: "task/create",
      PROJECT_CREATE: "project/create",
      GET_PROJECT_ID: "project/findOne",
      GET_PROJECT: "project/findAll",
      USER_EDIT: "user/update",
      GET_USER_ID: "user/findOne/",
      TASK_EDIT: "task/update",
      GET_TASK_ID: "task/findOne/",
      USER_DELETE: "user/delete/",
      TASK_DELETE: "task/delete/",
      DAILY_TASK_LIST:"dailyTask/list"



}
