import "./styles.scss";
import { Layout } from "antd";
import { Route, Routes } from "react-router-dom";

import LayoutHeader from "./layoutHeader";
import SideBar from "./sideBar";

import Dashboard from "../admin/dashboard";
import UserDataTable from "../admin/userScreen/dataTable";
import Userscreen from "../admin/userScreen";
import UserCreate from "../admin/userScreen/create";
import Taskscreen from "../admin/taskScreen";
import Taskcreate from "../admin/taskScreen/create";
import Projectscreen from "../admin/project";
import Projectcreate from "../admin/project/create";
import { Container } from "react-bootstrap";
import Viewscreen from "../admin/userScreen/view";
import DailytaskScreen from "../admin/dailyTask";
import Dailytaskcreate from "../admin/dailyTask/create";



function Routing() {
  const { Sider, Content } = Layout;
  return (
    <Layout>
      <Sider
        style={{ backgroundColor: "#0f415f", height: "100vh" }}
        width={250}
      >
        <SideBar />
      </Sider>
      <Layout>
        <LayoutHeader />
        {/* <Container> */}
        {/* <div style={{padding:"30px"}}> */}

        <Content
          style={{ background: "#ffff", height: "70vh", overflow: "scroll" }}
        >
          <Routes>
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='userDataTable' element={<UserDataTable />} />
            <Route path='userScreen' element={<Userscreen />} />
            <Route path='userScreen/userCreate' element={<UserCreate />} />
            <Route path='taskScreen/Taskcreate' element={<Taskcreate />} />
            <Route path='projectScreen/Projectcreate' element={<Projectcreate />} />
            <Route path='DailytaskScreen/Dailytaskcreate' element={<Dailytaskcreate />} />
            <Route path='taskScreen' element={<Taskscreen />} />
            <Route path='projectScreen' element={<Projectscreen />} />
            <Route path='DailytaskScreen' element={<DailytaskScreen />} />
           

          </Routes>
        </Content>
        {/* </Container> */}
        {/* </div> */}

      </Layout>
    </Layout>
  );
}

export default Routing;

// {
//   "id": "14",
//   "title": "Discount",
//   "icon": "CiDiscount1",
//   "navigate": "/Auth/discount"
// },
