import "./styles.scss";
// import loading from "../../assets/images/loading.gif";

const Loading = () => {
  return (
    <div className="Loading-box">
      <img src={"loading"} />
      <div> &nbsp; Loading . . . </div>
    </div>
  );
};

export default Loading;
