import React from "react";
import { Button, Popover } from "antd";
import { RiUserSmileLine } from "react-icons/ri";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import PopoverItem from "./popoverItem";
import Clock from "react-live-clock";
// import QuickMenu from "../components/quickMenu";

function LayoutHeader() {
//   const user = useSelector((state: any) => state?.User?.user);

  return (
    <div className="LayoutHeader">
      <div style={{ flex: 1, display: "flex" }}>
        {/* <Popover content={<QuickMenu />} placement="bottomLeft">
          <Button type="primary">Quick Access</Button>
        </Popover> */}
        &nbsp; &nbsp;
        <div className="LayoutHeader-timer">
          <Clock format={"h:mm:ss A"} ticking={true} />
        </div>
      </div>
      <div className="LayoutHeader-box">
        <div>asmil</div>&nbsp;&nbsp;
        {/* <div>
          <Popover placement="bottomLeft" content={<PopoverItem />}>
            <RiUserSmileLine fontSize={40} />
          </Popover>
        </div> */}
      </div>
    </div>
  );
}

export default LayoutHeader;
