import React, { useEffect, useState } from "react";
import PageHeader from "../../components/pageHeaders";
import { Button, Card, DatePicker, Form, Input } from "antd";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading";
import Projecttable from "./datatable";
import { API } from "../../config/apis";
import { GET } from "../../utils/apiRequest";

function Projectscreen() {
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState<any>({});
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [activities, setActivities] = useState<any>([]);
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    getProject();
  }, []);
  const getProject = async () => {
    try {
      let api = API.GET_PROJECT;
      const response: any = await GET(api, null);
      if (response?.status) {
        setUsers(response?.data);
        setIsLoading(false);
        // setMeta(response?.meta);
      } else {
        setUsers([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <PageHeader loading={isLoading2} backButton={true} title={"Project"}>
        <Form
        //  form={form} onValuesChange={getBooking}
        >
          <div className="bookingScreen-PageHeaderBox">
            <Form.Item name={"query"} noStyle>
              <Input
                style={{ width: 200 }}
                placeholder="Search"
                allowClear
                suffix={<CiSearch size={16} color="#000" />}
              />
            </Form.Item>
            <Form.Item name={"UserId"} noStyle>
              <Input placeholder="User id" style={{ width: 200 }} allowClear />
            </Form.Item>
            <Form.Item name={"date"} noStyle>
              <DatePicker placeholder="Create Date" style={{ minWidth: 100 }} />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                onClick={() => navigate("/Auth/projectScreen/Projectcreate")}
                type="primary"
              >
                Create +
              </Button>
            </Form.Item>
          </div>
        </Form>
      </PageHeader>
      <Card>
        {isLoading ? (
          <Loading />
        ) : (
          <Projecttable
            data={users}
            //   onEdit={(item: any) => createOrEditBooking(item)}
            //   onView={(item: any) => onView(item)}
            //   delete={(id: any) => deleteBooking(id)}
            meta={meta}
            onPageChange={(page: number, pageSize: number) => {
              setIsLoading2(true);
              setPage(page);
              setTake(pageSize);
            }}
          />
        )}
      </Card>
    </div>
  );
}

export default Projectscreen;
