import { Button, Select, Form, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GET, POST } from "../../utils/apiRequest";
import { API } from "../../config/apis";
import { useSelector } from "react-redux";
import PageHeader from "../../components/pageHeaders";

function Dailytaskcreate() {
  const [isLoading, setIsLoading] = useState(false);
  const User = useSelector((item: any) => item?.User?.user);
  const [isLoading2, setIsLoading2] = useState(true);
  const naviagte = useNavigate();

  

  const createproject = async (val: any) => {
    const payload = {
      title: val.title,
      description: val.description,
      // assignedTo: val.assignedTo,
      // id:val.id,
      status: val.status,
      // project: val.project,
      createdBy: Number(User?.id),
    };
    console.log("payload -->", payload);
    console.log("API.PROJECT_CREATE -->", API.PROJECT_CREATE);
    const response: any = await POST(API.PROJECT_CREATE, payload);
    if (response.status) {
      setIsLoading(false);
      notification.success({
        message: "success",
        description: "Project created",
      });
    } else {
      setIsLoading(false);
      notification.error({
        message: "Failed",
        description: "Something Went wrong",
      });
    }
  };
  return (
    <>
      <PageHeader loading={isLoading2} backButton={true} title={"Create"}>
        {" "}
      </PageHeader>
      <Container>
        <Form
          //   form={"form"}
          layout="vertical"
          name="userCreationForm"
          onFinish={createproject}
          // initialValues={{
          //     project: '',
          //     phone: '',
          //     email: '',
          //     id: '',
          // }}
        >
          <Row>
            <Col md={6}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "enter title" }]}
              >
                <Input size="large" placeholder="title" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: "Please enter description" },
                  // { message: 'Please enter a valid 10-digit phone number!' }
                ]}
              >
                <Input size="large" placeholder="Description" />
              </Form.Item>
            </Col>

            <Col md={6}>
              <Form.Item
                label="Status"
                name="status"
                rules={[{ required: true, message: "Please enter status" }]}
              >
                <Select size="large" placeholder="Select Status" allowClear>
                  <Select.Option value="open">Open</Select.Option>
                  <Select.Option value="in-progress">In Progress</Select.Option>
                  <Select.Option value="completed">Completed</Select.Option>
                  <Select.Option value="closed">Closed</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Row>
              <Col md={6}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  Submit
                </Button>
              </Col>
              <Col md={6}>
                <Button size="large" danger block onClick={() => naviagte(-1)}>
                  Close
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      </Container>
    </>
  );
}

export default Dailytaskcreate;
