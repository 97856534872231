import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
function PageHeader(props: any) {
  const navigation = useNavigate();
  return (
    <div className="PageHeader">
      {props?.backButton ? (
        <div
          onClick={() => navigation(props?.goBack ?? -1)}
          className="PageHeader-back"
        >
          <IoMdArrowBack />
        </div>
      ) : null}

      <div className="PageHeader-Box">
        <div className="PageHeader-text1">{props?.title}</div>
        <div>Dashboard / {props?.title}</div>
      </div>
      <div style={{ flex: 1 }} />
      {props?.children ? <div>{props?.children}</div> : null}
    </div>
  );
}

export default PageHeader;
