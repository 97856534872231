import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Spin } from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../../config/apis";
import { GET } from "../../../utils/apiRequest";

function Taskscreenmodal(props: any) {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props?.id) {
      loadtaskDetails(); 
    }
  }, [props?.id]);

  const loadtaskDetails = async () => {
    try {
      setLoading(true);
      let api = API.GET_TASK_ID + props?.id;
      const response: any = await GET(api, null);
      if (response?.status) {
        setData(response?.data?.task)
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={props?.open}
      title="Title"
      onCancel={props?.cancel}
    >
      {loading ? <Spin /> : 
        <Row gutter={[16, 16]} className="gap-4">
          <Col md={12}>
          Title{" "}
            <Input
              size="large"
              value={`${data?.title}`} // Set name from API data
              readOnly // Make input read-only
            />
          </Col>
          <Col md={12}>
           Description
            <Input
              size="large"
              value={data?.description} // Set office email from API data
              readOnly
            />
          </Col>

          <Col md={12}>
            Status
            <Input
              size="large"
              value={data?.status} // Set personal email from API data
              readOnly
            />
          </Col>
          <Col md={12}>
            Project
            <Input
              size="large"
              value={data?.project} // Set personal email from API data
              readOnly
            />
          </Col>
        </Row>
      }
    </Modal>
  );
}

export default Taskscreenmodal;
