import { IconType } from "react-icons";
import { CgUnavailable } from "react-icons/cg";
import { GiProgression } from "react-icons/gi";
import {
  MdDashboard,
  MdOutlineLocalOffer,
  MdOutlineWork,
} from "react-icons/md";
import { TbBrandBooking } from "react-icons/tb";
import { FaNetworkWired } from "react-icons/fa";
import { IoMdContacts } from "react-icons/io";
import { PiRadioactiveFill } from "react-icons/pi";
import { IoTicketSharp } from "react-icons/io5";
import { BiSolidReport } from "react-icons/bi";
import { RiLoginCircleFill } from "react-icons/ri";
import { MdOutlineWeb } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { IoCardSharp } from "react-icons/io5";
import { GrTransaction } from "react-icons/gr";
import { MdOutlineFileDownload } from "react-icons/md";
import { CiDiscount1 } from "react-icons/ci";
import { FaUser } from "react-icons/fa";
import { BsPersonWorkspace } from "react-icons/bs";
import { TbLayoutGridFilled } from "react-icons/tb";
import { VscTasklist } from "react-icons/vsc";


function DynamicIcon(props: any) {
  type IconName =
    | "MdDashboard"
    | "TbBrandBooking"
    | "CgUnavailable"
    | "GiProgression"
    | "MdOutlineWork"
    | "FaNetworkWired"
    | "IoMdContacts"
    | "PiRadioactiveFill"
    | "IoTicketSharp"
    | "BiSolidReport"
    | "RiLoginCircleFill"
    | "MdOutlineWeb"
    | "IoMdSettings"
    | "GrTransaction"
    | "IoCardSharp"
    | "MdOutlineFileDownload"
    | "MdOutlineLocalOffer"
    | "CiDiscount1"
    | "FaUser"
    | "BsPersonWorkspace"
    | "TbLayoutGridFilled"
    | "VscTasklist"

  interface IconProps {
    iconName: IconName;
    size?: number;
    color?: string;
  }

  function Icon({ iconName, size = 25, color = "#0a2f35" }: IconProps) {
    const icons: Record<IconName, IconType> = {
      MdDashboard: MdDashboard,
      TbBrandBooking: TbBrandBooking,
      CgUnavailable: CgUnavailable,
      GiProgression: GiProgression,
      MdOutlineWork: MdOutlineWork,
      FaNetworkWired: FaNetworkWired,
      IoMdContacts: IoMdContacts,
      PiRadioactiveFill: PiRadioactiveFill,
      IoTicketSharp: IoTicketSharp,
      BiSolidReport: BiSolidReport,
      RiLoginCircleFill: RiLoginCircleFill,
      MdOutlineWeb: MdOutlineWeb,
      IoMdSettings: IoMdSettings,
      IoCardSharp: IoCardSharp,
      GrTransaction: GrTransaction,
      MdOutlineLocalOffer: MdOutlineLocalOffer,
      MdOutlineFileDownload: MdOutlineFileDownload,
      CiDiscount1: CiDiscount1,
      FaUser: FaUser,
      BsPersonWorkspace: BsPersonWorkspace,
      TbLayoutGridFilled: TbLayoutGridFilled,
      VscTasklist: VscTasklist
    };

    if (!icons.hasOwnProperty(iconName)) {
      console.warn(
        `Icon '${iconName}' not found. Rendering default icon instead.`
      );
      iconName = "CgUnavailable"; // set default icon name
    }

    const IconComponent = icons[iconName];

    return <IconComponent size={size} />;
  }

  return <Icon iconName={props.icon} size={props.size} />;
}

export default DynamicIcon;
