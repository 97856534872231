import { Button, Form, Input, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../../config/apis";
import { GET, POST } from "../../utils/apiRequest";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import PageHeader from "../../components/pageHeaders";
import "./styles.scss";
function Taskcreate() {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const User = useSelector((item: any) => item?.User?.user);
  const location = useLocation();
  const [isLoading2, setIsLoading2] = useState(true);

  const { id } = location.state || {};

  useEffect(() => {
    getProject();
    fetchUsers();
  }, []);

  const getProject = async () => {
    try {
      let api = API.GET_PROJECT;
      const response: any = await GET(api, null);
      if (response?.status) {
        console.log(response?.data);
        setProjects(response?.data);
        setIsLoading(false);
        // setMeta(response?.meta);
      } else {
        setProjects([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUsers = async () => {
    try {
      let api = API.GET_USER + `?page=1&take=1000`;
      const response: any = await GET(api, null);
      if (response?.status) {
        setUsers(response?.data);
        setIsLoading(false);
      } else {
        setUsers([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const createtask = async (val: any) => {
    const payload = {
      title: val.title,
      description: val.description,
      assignedTo: Number(val?.assignedTo),
      status: val.status,
      project: Number(val.project),
      createdBy: Number(User?.id),
      parentId: Number(val.project),
      id: Number(id),
    };
    
    let url = id ? API.TASK_EDIT : API.TASK_CREATE;
    const data: any = await POST(url, payload);

    if (data.status) {
      navigate(-1);
      setIsLoading(false);
      notification.success({
        message: "success",
        description: `Task Successfully ${id ? "Updated" : "create"}`,
      });
    } else {
      setIsLoading(false);
      notification.error({
        message: "Failed",
        description: "Something Went wrong",
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (id) loadtaskDetails();
  }, []);

  const loadtaskDetails = async () => {
    try {
      setIsLoading2(true);
      let api = API.GET_TASK_ID + id;
      const response: any = await GET(api, null);
      console.log(
        "response=======response======responseresponse======",
        response
      );
      if (response?.status) {
        const data = response?.data?.task;
        console.log('get 1 task -->', data)
        form.setFieldsValue({
          title: data?.title,
          description: data?.description,
          assignedTo: data?.assignedTo?.toString(),
          status: data?.status?.toString(),
          project: data?.project?.toString(),
        });
      }
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };

  return (
    <>
      <PageHeader loading={isLoading2} backButton={true} title={"Create"}>
        {" "}
      </PageHeader>
      <br />
      <div className="Task-createBox1">
        <Form
          form={form}
          layout="vertical"
          name="userCreationForm"
          onFinish={createtask}
        >
          <Row>
            <Col md={6}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "enter title" }]}
              >
                <Input size="large" placeholder="title" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: "Please enter description" },
                ]}
              >
                <Input size="large" placeholder="Description" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Assigned to"
                name="assignedTo"
                rules={[{ required: true, message: "Please assign" }]}
              >
                <Select size="large" placeholder="Select Assignee" allowClear>
                  {users.map((item: any) => (
                    <Select.Option
                      key={item?.id}
                      value={item?.id}
                      children={`${item?.firstName} ${item?.lastName}`}
                    />
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={6}>
              <Form.Item
                label="Status"
                name="status"
                rules={[{ required: true, message: "Please enter status" }]}
              >
                <Select size="large" placeholder="Select Status" allowClear>
                  <Select.Option value="open">Open</Select.Option>
                  <Select.Option value="in-progress">In Progress</Select.Option>
                  <Select.Option value="completed">Completed</Select.Option>
                  <Select.Option value="closed">Closed</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Project"
                name="project"
                rules={[{ required: true, message: "Please select a project" }]}
              >
                <Select
                  // mode="multiple"
                  size="large"
                  placeholder="Select Project"
                  allowClear
                >
                  {projects.map((item: any) => (
                    <Select.Option
                      key={item?.id}
                      value={item?.id}
                      children={item?.title}
                    />
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Row>
              <Col md={6} />

              <Col md={3}>
                <Button size="large" danger block onClick={() => navigate(-1)}>
                  Close
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default Taskcreate;
