import { Pagination, Popconfirm, Table, Tooltip } from 'antd';
import React from 'react'
import { AiOutlineDelete } from 'react-icons/ai';
import { CiEdit } from 'react-icons/ci';

function Projecttable(props:any) {
    const columns = [
        {
          title: "Id",
          dataIndex: "id",
          key: "id",
          render: (id: any, __: any, rowIndex: number) => {
            return <div className="table-Txt">{id}</div>;
          },
        },
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
          render: (record: any) => {
            return <div className="table-Txt">{record}</div>;
          },
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
          render: (record: any) => {
            return <div className="table-Txt">{record}</div>;
          },
        },
       
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (record: any) => {
            return <div className="table-Txt">{record}</div>;
          },
        },
      
        {
          title: "Action",
          with: 10,
          render: (item: any) => (
            <div    
              className="table-item"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Tooltip title="View" mouseEnterDelay={0.5} color="#8f8e8d">
                  {/* <FaTrash
                    size={20}
                    style={{ cursor: "pointer", color: "blue" }}
                    // onClick={() => props?.onView(item?.id)}
                  /> */}
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Edit" mouseEnterDelay={0.5} color="#8f8e8d">
                  <CiEdit
                    size={20}
                    onClick={() => props?.onEdit(item)}
                    style={{ cursor: "pointer", color: "orange" }}
                  />
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Delete" mouseEnterDelay={0.5} color="#8f8e8d">
                  <Popconfirm
                    title="Delete the Staff's Details"
                    description="Are you sure you want to delete the Staff Details?"
                    onConfirm={() => props?.delete(item?.id)}
                    okText="Yes"
                  >
                    <AiOutlineDelete
                      size={20}
                      style={{ color: "red", cursor: "pointer" }}
                    />
                  </Popconfirm>
                </Tooltip>
              </div>
            </div>
          ),
        },
      ];
  return (
    <>
     <>
          <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
      />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          showTotal={(total) => `Total ${props?.meta?.total_count} Bookings`}
          onChange={(page, pageSize) => props.onPageChange(page, pageSize)}
        />
      </div>
        </>
    </>
  )
}

export default Projecttable