import { Pagination, Popconfirm, Table, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";

function DataTable(props: any) {
  const columns = [
    {
      title: "S.NO",
      dataIndex: "id",
      key: "id",
      render: (id: any, __: any, rowIndex: number) => {
        return <div className="table-Txt">{rowIndex + 1}</div>;
      },
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (record: any) => {
        return <div className="table-Txt">{record}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (record: any) => {
        return (
          <div className="table-Txt">{moment(record).format("DD-MM-YYYY")}</div>
        );
      },
    },
    {
      title: "User",
      dataIndex: "",
      key: "",
      render: (_: any, record: any) => {
        return (
          <div className="table-Txt">
            {record?.["created_by.firstName"]} {record?.["created_by.lastName"]}
          </div>
        );
      },
    },
    {
      title: "Action",
      with: 10,
      render: (item: any) => (
        <div
          className="table-item"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            {/* <Tooltip title="view" mouseEnterDelay={0.5} color="#8f8e8d"> */}
            <IoEyeOutline
              //   onClick={() => {
              //     setId(item?.id)
              //     props?.setOpen(true);
              //   }}
              size={20}
              style={{ color: "blue", cursor: "pointer" }}
            />
            {/* </Tooltip> */}
          </div>
          <div>
            <Tooltip title="Edit" mouseEnterDelay={0.5} color="#8f8e8d">
              <CiEdit
                size={20}
                // onClick={() =>
                //   navigate("/Auth/taskScreen/Taskcreate", {
                //     state: { id: item?.id },
                //   })
                // }
                style={{ cursor: "pointer", color: "orange" }}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Delete" mouseEnterDelay={0.5} color="#8f8e8d">
              <Popconfirm
                title="Delete Task"
                description="Are you sure you want to delete Task"
                onConfirm={() => props?.delete(item?.id)}
                okText="Yes"
              >
                <AiOutlineDelete
                  size={20}
                  style={{ color: "red", cursor: "pointer" }}
                />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
      />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.meta?.total_count}
          showSizeChanger
          // showTotal={(total) => `Total ${props?.meta?.total_count} User`}
          // onChange={(page, take) => props.onPageChange(page, take)}
          pageSizeOptions={[10, 20, 50, 100]}
          defaultPageSize={10}
        />
      </div>
      {/* <Taskscreenmodal open={props?.open} cancel={()=> props?.setOpen(false)} id={id}/> */}
    </>
  );
}

export default DataTable;
