// import React from 'react'

// function Viewscreen() {
//   return (
//     <div>view</div>
//   )
// }

// export default Viewscreen
import React, { useEffect, useState } from "react";
import { Form, Input, Modal } from "antd";
import { API } from "../../config/apis";
import { GET } from "../../utils/apiRequest";
import { Col, Row } from "react-bootstrap";

const Viewscreen = (props: any) => {
  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (props?.id) loadUserDetails();
  }, [props?.id]);

  const loadUserDetails = async () => {
    try {
      let api = API.GET_USER_ID + props?.id;
      const response: any = await GET(api, null);

      console.log("loadUserDetails -ndooooooooooooooooo->", response);
      if (response?.status) {
        const data = response?.data;
        setData(data);
        //   form.setFieldsValue({
        //     employeeId: data?.employeeId,
        //     firstName: data?.firstName,
        //     lastName: data?.lastName,
        //     phone: data?.phone,
        //     email: data?.email,
        //     officeEmail: data?.officeEmail,
        //   });
      }
    } catch (err) {}
  };

  return (
    <Modal
      open={props?.open}
      title="Title"
      // onOk={props?.ok}
      onCancel={props?.cancel}
    >
      <Row gutter={[16, 16]} className="gap-4">
        <Col md={12}>
          Name{" "}
          <Input
            size="large"
            value={`${data?.firstName} ${data?.lastName}`}
          ></Input>
        </Col>

        <Col md={12}>
          Employee Id
          <Input size="large" value={data?.employeeId} />
        </Col>

        <Col md={12}>
          Office Mail
          <Input size="large" value={data?.officeEmail} />
        </Col>
        <Col md={12}>
          Email
          <Input size="large" value={data?.email} />
        </Col>
      </Row>
    </Modal>
  );
};

export default Viewscreen;
