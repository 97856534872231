import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import LoginScreen from './website/login';
import Dashboard from './admin/dashboard';
import Routing from './navigation';
import UserDataTable from './admin/userScreen/dataTable';
import ProtectedRoute from './navigation/protectRouter';
import { useSelector } from 'react-redux';
import Userscreen from './admin/userScreen';
import Create from './admin/userScreen/create';


function App() {
  const User = useSelector((state: any) => state.User);

  return (
    <div className="App">
      <Routes>
        <Route index element={<LoginScreen />} />
        <Route index element={<Userscreen />} />
    

        <Route
          path="/Auth/*"
          element={
            <ProtectedRoute isSignedIn={User.auth}>
                <Routing />
                
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
