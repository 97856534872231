import { useNavigate, useLocation } from "react-router-dom";
import Menu from "./menu.json";
// import Logo from "../config/logo-white.svg";
import "./styles.scss";
import DynamicIcon from "./dynamicIcon";
function SideBar() {
  const navigate = useNavigate();
  let location = useLocation();
  return (
    <div className="sideBar-Box">
      {/* <div className="sideBar-logo">
        <img src={"Logo"} alt="" />
      </div> */}
      <div>
        <div className="sideBar-txt1">MAIN MENUS</div>
        {Menu?.map((item: any) => {
          return (
            <div
              key={item?.id}
              onClick={() => navigate(item?.navigate)}
              className={
                location.pathname === item?.navigate
                  ? "sideBar-item active"
                  : "sideBar-item"
              }
            >
              <DynamicIcon icon={item?.icon} />
              <div style={{ marginLeft: 20 }} />
              <div>{item?.title}</div>
            </div>
          );
        })}
        
      </div>
    </div>
  );
}

export default SideBar;
