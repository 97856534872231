import { Button, Card, DatePicker, Form, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import UserDataTable from "./dataTable";
import PageHeader from "../../components/pageHeaders";
import Loading from "../../components/loading";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/apis";
import { DELETE, GET } from "../../utils/apiRequest";
import Viewscreen from "../viewModal/view";

function Userscreen() {
  const navigate = useNavigate();
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>({});
  const [data, setData] = useState([]);
  const [userIdSearch, setUserIdSearch] = useState("");
  const [Notifications, contextHolder] = notification.useNotification();
  const [querry, setQuerry] = useState("");
  const [modal, setModal] = useState(false);
  const [userId, setUserId] = useState();
  console.log('userid',userId)

  useEffect(() => {
    getUsers(search, userIdSearch, page, take);
  }, []);

  const getUsers = async (search: any, userId: any, page: any, take: any) => {
    try {
      setIsLoading2(true);
      let api =
        API.GET_USER +
        `?search=${search ?? ""}&page=${page}&take=${take}&userId=${userId}`;
      const response: any = await GET(api, null);
      console.log("user list -->", response);
      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
        setMeta(response?.meta);
      } else {
        setData([]);
        setIsLoading(false);
      }
      setIsLoading2(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoading2(false);
    }
  };
  const deleteUser = async (id: any) => {
    try {
      setIsLoading2(true);
      let api = API.USER_DELETE + id;
      let Delete: any = await DELETE(api);
      if (Delete?.status) {
        setIsLoading2(false);
        Notifications["success"]({
          message: "User Deleted",
        });
        getUsers(search, userIdSearch, page, take);
      } else {
        setIsLoading2(false);
        Notifications["error"]({
          message: "User Not Deleted",
        });
      }
    } catch (err) {
      setIsLoading2(false);
      Notifications["error"]({
        message: "Network error. task not deleted",
      });
    }
  };

  const openModal = (open:boolean,id:any)=>{
      setUserId(id)
      setModal(open)
  }

  return (
    <div>
      <PageHeader loading={isLoading2} backButton={true} title={"user"}>
        {contextHolder}
        <Form
        //  form={form} onValuesChange={getBooking}
        >
          <div className="bookingScreen-PageHeaderBox">
            <Form.Item name={"query"} noStyle>
              <Input
                style={{ width: 200 }}
                placeholder="Search"
                onChange={(value: any) => {
                  setSearch(value?.target?.value);
                  getUsers(value?.target?.value, userIdSearch, page, take);
                }}
                allowClear
                suffix={<CiSearch size={16} color="#000" />}
              />
            </Form.Item>
            {/* <Form.Item name={"UserId"} noStyle>
              <Input
                placeholder="User id"
                style={{ width: 200 }}
                allowClear
                onChange={(value: any) => {
                  setUserIdSearch(value?.target?.value);
                  getUsers(search, value?.target?.value, page, take);
                }}
              />
            </Form.Item> */}
&nbsp; &nbsp;

            <Form.Item noStyle>
              <Button
                onClick={() => navigate("/Auth/userScreen/userCreate")}
                type="primary"
              >
                Create +
              </Button>
            </Form.Item>
          </div>
        </Form>
      </PageHeader>
      <Card>
        {isLoading ? (
          <Loading />
        ) : (
          <UserDataTable
            view={(open: boolean,id:any) => openModal(open,id)}
            userId={(data: any) => setUserId(data)}
            data={data}
            delete={(id: any) => deleteUser(id)}
            meta={meta}
            onPageChange={(page: number, take: number) => {
              setIsLoading2(true);
              setPage(page);
              setTake(take);
              getUsers(search, userIdSearch, page, take);
            }}
          />
        )}
      </Card>
      {modal && modal ? (
        <Viewscreen
          open={modal}
          id={userId}
          cancel={() => setModal(false)}
          ok={() => console.log("OK")}
        />
      ) : null}
    </div>
  );
}

export default Userscreen;
