
    import React from 'react'
    
import { Col, Container, Row } from 'react-bootstrap'
import Cards from './components/cards'
import { FaRegCalendarCheck } from 'react-icons/fa'
import "./styles.scss";
    function index() {
      return (
<>
<Container>
      <br />
      <Row>
        <Col md={3}>
          <Cards
            icon={<FaRegCalendarCheck />}
            // label={"Bookings"}
            value={"booking"}
          />
        </Col>
        <Col md={3}>
          <Cards
            icon={<FaRegCalendarCheck />}
            // label={"Tickets"}
            value={"Tickets"}
          />
        </Col>
        <Col md={3}>
          {/* <Cards
            icon={<FaRegCalendarCheck />}
            label={"Staffs"}
            value={Staffs}
          /> */}
        </Col>
        <Col md={3}>
          {/* <Cards
            icon={<FaRegCalendarCheck />}
            label={"Activities"}
            value={Activies}
          /> */}
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={7}>
        <div className="dashboard-Txt1"></div>
          <div className="Dashboard-graph">
            <br/>
            {/* <GraphChart
              data={{
                data: graphData?.values,
                labels:graphData?.labels
              }}
            /> */}
          </div>
        </Col>
        <Col md={5}>
          <div className="dashboard-Txt1"></div>
        {/* <Bookings data={bookings}/> */}
        </Col>
      </Row>
    </Container>
</>
      )
    }
    
    export default index